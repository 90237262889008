<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="
      () => {
        visible = false
      }
    "
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapperStyle="{ background: 'transparent' }"
              :offsetTop="16"
              :getContainer="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link
                href="#alert"
                :title="`当前告警 (${count.alert})`"
              />
              <a-anchor-link href="#monitor" title="监控" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> 基本信息</span>
              </div>
              <div style="padding: 0 16px">
                <a-descriptions :column="3">
                  <a-descriptions-item label="名称">
                    {{ detail.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="所属操作系统">
                    {{ detail.os ? detail.os.name : '-' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="IP 地址">
                    {{ detail.os ? detail.os.monitor_address : '-' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="版本">
                    {{ detail.version }}
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间">
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="所属群组">
                    <a-tag v-for="group in detail.groups" :key="group.id">
                      {{ group.name }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="状态">
                    <source-status-tag
                      :status="detail.status"
                    ></source-status-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="监控状态">
                    <monitor-status-tag
                      v-for="item in monitorStatuses"
                      :key="item.name"
                      :status="item.value"
                      :name="item.name"
                    ></monitor-status-tag>
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </div>

            <div id="alert">
              <div class="small-module-title">
                <a-icon type="alert"></a-icon>
                <span> 当前告警 ({{ count.alert }})</span>
              </div>

              <div style="padding: 0 16px">
                <div style="height: 24px; margin-bottom: 24px">
                  <severity-radio-group
                    v-model="currentSeverity"
                    style="float: right"
                  ></severity-radio-group>
                </div>

                <div
                  class="overflow-scroll-hidden"
                  style="max-height: 400px; overflow: scroll"
                >
                  <alert-timeline
                    :severity="currentSeverity"
                    :source-id="detail.id"
                    source-type="containerization"
                    @total="v => (count.alert = v)"
                    style="margin-top: 8px"
                  ></alert-timeline>
                </div>
              </div>
            </div>

            <div id="monitor">
              <div class="small-module-title">
                <a-icon type="line-chart"></a-icon>
                <span> 监控</span>
              </div>
              <div style="padding: 0 16px">
                <div style="margin-bottom: 16px">
                  <datetime-range
                    :allow-clear="false"
                    :default-value="datetimeRange"
                    @ok="
                      v => {
                        datetimeRange = v
                      }
                    "
                  ></datetime-range>
                </div>

                <a-row :gutter="8">
                  <a-col
                    v-for="item in monitorItems"
                    :key="item.key"
                    :sm="24"
                    :lg="12"
                    style="margin-bottom: 8px"
                  >
                    <monitor-chart-card
                      :datetime-range="datetimeRange"
                      :history-func="historyFunc"
                      :item="item"
                      :source-id="detail.id"
                    ></monitor-chart-card>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="monitor" tab="监控指标">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="rule" tab="告警规则">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import {
  getContainerization,
  getContainerizationMonitorStatusList,
  getContainerizationMonitorHistory,
  getContainerizationMonitorItemList,
  getContainerizationMonitorTriggerList,
  updateContainerizationMonitorTrigger
} from '@/api/containerization'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorItemTable from '@/components/table/MonitorItemTable'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'
import MonitorChartCard from '@/components/card/MonitorChartCard'

export default {
  name: 'ContainerizationDrawer',
  props: {
    containerizationType: {
      type: String
    }
  },
  components: {
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    SourceStatusTag,
    MonitorStatusTag,
    SeverityRadioGroup,
    DatetimeRange,
    MonitorItemTable,
    MonitorTriggerTable,
    MonitorChartCard
  },
  data () {
    return {
      visible: false,
      currentSeverity: 'all',
      detail: {
        id: '',
        name: '',
        version: '',
        monitor_port: '',
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown',
        os: undefined
      },
      monitorStatuses: [],
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      count: {
        alert: 0
      },
      total: 0,
      historyFunc: getContainerizationMonitorHistory,
      getItemListFunc: getContainerizationMonitorItemList,
      getTriggerFunc: getContainerizationMonitorTriggerList,
      updateFunc: updateContainerizationMonitorTrigger
    }
  },
  computed: {
    monitorItems () {
      switch (this.containerizationType) {
        case 'docker':
          return [
            {
              key: 'docker.containers.running',
              title: '运行中的容器数'
            },
            {
              key: 'docker.containers.stopped',
              title: '已停止的容器数'
            }
          ]
        case 'kubernetes':
          return [
            {
              key: 'kube.kubelet.pods.running',
              title: '运行中的 Pod 数量'
            },
            {
              key: 'kube.kubelet.containers.running',
              title: '运行中的 Container 数量 '
            }
          ]
        default:
          return []
      }
    }
  },
  methods: {
    show (id) {
      getContainerization(id, { containerization_type: 'docker' })
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
      getContainerizationMonitorStatusList(id).then(res => {
        this.monitorStatuses = res.data.data
      })
    }
  }
}
</script>

<style lang="less">
.detail-drawer {
  .small-module-title {
    height: 35px;
    background: #e4f1ff;
    border-radius: 8px;
    color: #096dd9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .button-container {
    text-align: right;
    padding: 0 0 8px 0;

    a {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .active {
      color: #1890ff;
    }
  }

  .unit-card {
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
  .ant-card {
    box-shadow: 0 0 8px #0000001a !important;
  }
}
</style>

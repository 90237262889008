<template>
  <div>
    <list-layout
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :tools-list="toolsList"
      :default-selected="tableDefaultSelectData"
      :get-table-data-func="getTableDataFunc"
      :group-tree-func="groupTreeFunc"
      :refresh="updateTableFlag"
      :table-operation-width="240"
      :customer-params="params"
      @tableSelectData="getSelectData"
      :apiPermission="groupApiPermission"
      :can-drag-column-width="true"
    >
      <template #leftTool v-if="!fetchParams.discovered">
        <create-button
          v-permission="{
            action: 'base.containerization.create',
            effect: 'disabled'
          }"
          @click="$refs.inputDrawer.show(null, fetchParams.containerization_type)"
        ></create-button>
      </template>
      <template #name="{ slotProps }">
        <a-tooltip
          v-if="slotProps.record.discovered"
          title="自动发现"
          style="margin-right: 4px"
        >
          <a-icon
            theme="filled"
            type="info-circle"
            style="color: #1890ff"
          ></a-icon>
        </a-tooltip>
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a
            v-if="!slotProps.record.discovered"
            @click="$refs.containerizationDrawer.show(slotProps.record.id)"
          >
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag :status="slotProps.text"></source-status-tag>
      </template>
      <template #os="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text.name">
          <a @click="$refs.osDrawer.show(slotProps.text.id)">
            {{ slotProps.text.name }}
          </a>
        </a-tooltip>
      </template>
      <template #operation="{ slotProps }">
        <template v-if="!slotProps.record.discovered">
          <sync-button @click="sync(slotProps.record.id)"></sync-button>
          <edit-button
            v-permission="{
              action: 'base.containerization.update',
              effect: 'disabled'
            }"
            @click="$refs.inputDrawer.show(slotProps.record.id, fetchParams.containerization_type)"
          ></edit-button>
        </template>
        <a-button
          v-else
          icon="monitor"
          size="small"
          type="link"
          @click="monitor(slotProps.record.id)"
          style="color: #1890ff"
        >
          监控
        </a-button>
        <delete-button v-permission="{action: 'base.containerization.delete', effect: 'disabled'}" @confirm="confirm(slotProps.record.id)"></delete-button>
      </template>
    </list-layout>

    <os-drawer ref="osDrawer"></os-drawer>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
    <containerization-drawer
      ref="containerizationDrawer"
      :containerization-type="fetchParams.containerization_type"
    ></containerization-drawer>
  </div>
</template>

<script>
import {
  getContainerizationList,
  deleteContainerization,
  createContainerizationGroup,
  getContainerizationGroupList,
  deleteContainerizationGroup,
  updateContainerizationGroup,
  getContainerizationGroup,
  syncContainerization,
  monitorContainerization
} from '@/api/containerization'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import ContainerizationDrawer from '@/components/drawer/ContainerizationDrawer'
import SyncButton from '@/components/button/SyncButton'
import { hasPermission } from '@/utils'

export default {
  name: 'ContainerizationTable',
  props: {
    toolsList: {
      type: Array,
      default: () => []
    },
    fetchParams: {
      type: Object
    }
  },
  components: {
    'os-drawer': () => import('@/components/drawer/OSDrawer'),
    InputDrawer: () => import('./modules/InputDrawer'),
    ListLayout,
    EditButton,
    DeleteButton,
    SourceStatusTag,
    ContainerizationDrawer,
    SyncButton,
    CreateButton
  },
  data () {
    return {
      getTableDataFunc: getContainerizationList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'os.monitor_address',
          title: 'IP 地址',
          width: 160,
          scopedSlots: {
            customRender: 'monitorAddress'
          }
        },
        {
          dataIndex: 'os',
          title: '所属操作系统',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'os'
          }
        },
        {
          dataIndex: 'version',
          title: '版本',
          width: 120,
          ellipsis: true,
          scopedSlots: {
            customRender: 'version'
          }
        },
        {
          dataIndex: 'status',
          width: 100,
          title: '状态',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'os.monitor_address',
        'version',
        'os',
        'status'
      ],
      updateTableFlag: this.refresh,
      tableSelectData: [],
      tableDefaultSelectData: [],
      customerParams: this.$route.params,
      groupApiPermission: {
        create: 'service.containerization_group.create',
        edit: 'service.containerization_group.update',
        delete: 'service.containerization_group.delete'
      }
    }
  },
  computed: {
    params () {
      const obj = {}
      for (const key in this.customerParams) {
        if (Object.hasOwnProperty.call(this.customerParams, key)) {
          obj[key] = this.customerParams[key]
        }
      }
      for (const key in this.fetchParams) {
        if (Object.hasOwnProperty.call(this.fetchParams, key)) {
          obj[key] = this.fetchParams[key]
        }
      }
      return obj
    },
    groupTreeFunc () {
      if (hasPermission(['base.containerization_group.view'])) {
        return {
          createFunc: createContainerizationGroup,
          deleteFunc: deleteContainerizationGroup,
          getFunc: getContainerizationGroup,
          getListFunc: getContainerizationGroupList,
          updateFunc: updateContainerizationGroup
        }
      } else return null
    }
  },
  methods: {
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    async confirm (id = '') {
      if (id) {
        await deleteContainerization(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(
          this.tableSelectData.findIndex(item => item.key === id),
          1
        )
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    },
    sync (id) {
      syncContainerization({ id }).then(res => {
        this.$message.success(res.message)
      })
    },
    monitor (id) {
      monitorContainerization({ id }).then(res => {
        this.$message.success(res.message)
        this.updateTableFlag = !this.updateTableFlag
        if (this.fetchParams.containerization_type === 'kubernetes') this.$refs.InputDrawer.show(id, 'kubernetes')
      })
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>

<style>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('list-layout',{attrs:{"column-default-selected":_vm.columnDefaultSelected,"columns-all":_vm.columnsAll,"tools-list":_vm.toolsList,"default-selected":_vm.tableDefaultSelectData,"get-table-data-func":_vm.getTableDataFunc,"group-tree-func":_vm.groupTreeFunc,"refresh":_vm.updateTableFlag,"table-operation-width":240,"customer-params":_vm.params,"apiPermission":_vm.groupApiPermission,"can-drag-column-width":true},on:{"tableSelectData":_vm.getSelectData},scopedSlots:_vm._u([(!_vm.fetchParams.discovered)?{key:"leftTool",fn:function(){return [_c('create-button',{directives:[{name:"permission",rawName:"v-permission",value:({
          action: 'base.containerization.create',
          effect: 'disabled'
        }),expression:"{\n          action: 'base.containerization.create',\n          effect: 'disabled'\n        }"}],on:{"click":function($event){return _vm.$refs.inputDrawer.show(null, _vm.fetchParams.containerization_type)}}})]},proxy:true}:null,{key:"name",fn:function({ slotProps }){return [(slotProps.record.discovered)?_c('a-tooltip',{staticStyle:{"margin-right":"4px"},attrs:{"title":"自动发现"}},[_c('a-icon',{staticStyle:{"color":"#1890ff"},attrs:{"theme":"filled","type":"info-circle"}})],1):_vm._e(),_c('a-tooltip',{attrs:{"placement":"topLeft","title":slotProps.text}},[(!slotProps.record.discovered)?_c('a',{on:{"click":function($event){return _vm.$refs.containerizationDrawer.show(slotProps.record.id)}}},[_vm._v(" "+_vm._s(slotProps.text)+" ")]):_c('span',[_vm._v(_vm._s(slotProps.text))])])]}},{key:"status",fn:function({ slotProps }){return [_c('source-status-tag',{attrs:{"status":slotProps.text}})]}},{key:"os",fn:function({ slotProps }){return [_c('a-tooltip',{attrs:{"placement":"topLeft","title":slotProps.text.name}},[_c('a',{on:{"click":function($event){return _vm.$refs.osDrawer.show(slotProps.text.id)}}},[_vm._v(" "+_vm._s(slotProps.text.name)+" ")])])]}},{key:"operation",fn:function({ slotProps }){return [(!slotProps.record.discovered)?[_c('sync-button',{on:{"click":function($event){return _vm.sync(slotProps.record.id)}}}),_c('edit-button',{directives:[{name:"permission",rawName:"v-permission",value:({
            action: 'base.containerization.update',
            effect: 'disabled'
          }),expression:"{\n            action: 'base.containerization.update',\n            effect: 'disabled'\n          }"}],on:{"click":function($event){return _vm.$refs.inputDrawer.show(slotProps.record.id, _vm.fetchParams.containerization_type)}}})]:_c('a-button',{staticStyle:{"color":"#1890ff"},attrs:{"icon":"monitor","size":"small","type":"link"},on:{"click":function($event){return _vm.monitor(slotProps.record.id)}}},[_vm._v(" 监控 ")]),_c('delete-button',{directives:[{name:"permission",rawName:"v-permission",value:({action: 'base.containerization.delete', effect: 'disabled'}),expression:"{action: 'base.containerization.delete', effect: 'disabled'}"}],on:{"confirm":function($event){return _vm.confirm(slotProps.record.id)}}})]}}],null,true)}),_c('os-drawer',{ref:"osDrawer"}),_c('input-drawer',{ref:"inputDrawer",on:{"ok":_vm.confirm}}),_c('containerization-drawer',{ref:"containerizationDrawer",attrs:{"containerization-type":_vm.fetchParams.containerization_type}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }